import './app.css'
import MyImage from './assets/images/pokershots_bg.png'

function App() {
  return (
    <div className="main-container">
      <div className="text-container">
      <span className="pokershots-text">Pokershots</span>
      </div>
      <img src={MyImage} className="container-img" alt="pokershots_logo"/>
    </div>
  );
}

export default App;
